import { Link, Outlet } from "react-router-dom";
import { NavLink } from 'react-router-dom';
const Navbar=()=> {
  return (
    <>
    <NavLink to={'/'}> <h1>
           kissing the <h1 className='machine-font'>machine </h1>
           </h1> </NavLink> 
    <ul>
       <li>
          <NavLink to="/tune_in">
            <div className="menu-icon-container">
              <img className="icons" src = "/images/pc.png" alt=""/>
              <div className='menu-name' data-text="plug">tune_in();</div>
            </div>
          </NavLink>
        </li>


        <li>
          <NavLink to="/drown_out">
            <div className="menu-icon-container">
              <img className="icons" src = "/images/headphones.png" alt=""/>
            </div>
            <div className='menu-name' data-text="drown-out">drown_out();</div>
          </NavLink>
        </li>

        <li>
          <NavLink to="/tap_in">
            <div className="menu-icon-container">
              <img className="icons" src = "/images/plug1.png" alt=""/>
          </div>
          <div className='menu-name' data-text="tap-in">tap_in();</div>
           </NavLink>
        </li>


        <li>
          <NavLink to="/give_in">
            <div className="menu-icon-container">
               <img className="icons" src = "/images/sbc.png" alt=""/>
            </div>
            <div className='menu-name' data-text="give-in">give_in();</div>
          </NavLink>
        </li>

        <li>
          <NavLink to="/">
            <div className="menu-icon-container">
              <img className="icons" src = "/images/cpu-cabinet.png" alt=""/>
            </div>
            <div className='menu-name' data-text="restart"> restart();</div>
          </NavLink>
        </li>


      </ul>
      <main>
        <Outlet />
      </main>


{/* 
      <header className="navbar">
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="blogs">Blogs</Link>
            </li>
            <li>
              <Link to="contact">Contacts</Link>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <Outlet />
      </main> */}
    </>
  );
}

export default Navbar;