
import Navbar from "../components/NavBar";
import Marquee from "../components/Marquee";


export default function Home() {

  return (
    <>
          {/* ****REMEMBER: u want to put icons around the title, dont forget, just b sure 2 do after u have paragraphs font done  */}

      {/* FIGURE OUT HOW TO MAKE TEXT RESPONOSIVE */}
     
        <Navbar />

  
        <Marquee></Marquee>

   

      <div className="test-photo">
       <img src = "/images/IMG_8858.jpg" alt=""/>
        </div>

        <div className="test-photo-2">
       <img src = "/images/IMG_7908.jpeg" alt=""/>
        </div>

        <div className="test-photo-3">
       <img src = "/images/IMG_7889.jpeg" alt=""/>
        </div>

        <div className="test-photo-4">
       <img src = "/images/DSC04798.jpg" alt=""/>
        </div>

        <div className="test-photo-5">
       <img src = "/images/IMG_7925.jpg" alt=""/>
        </div>

    </>
  );
}
