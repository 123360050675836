import React from 'react';

function Marquee(props){
    return(

    <div className='marquee-component-container'> 
    
    <div className='poem-container'>
     <marquee className='marquee-class' behavior="slide" direction="down" scrollamount= "11">
      <p className='para-poem'> <span>i</span> kiss the machine and it kisses Me back with heavy static, <br/> 
       i enable it. undeniable-- painful chemistry <br/> the machine serves me with kisses full of electric shrills
      that chase my body each time it's near. <br/> i love it <br/> i'm tired of electrocution tho <br/>
      i am the furthest from reluctant <br/> i think i need batteries instead of an outlet <br/> What is pleasure
      without deep cognizance of pain  <br/>  
     <br/>
     </p>
   
     </marquee>
        </div>

    <div className="marquee-name-container">
        <marquee className='marquee-name-class' behavior="slide" direction="up" scrollamount= "5">
        <p className='-- jaiyn'>--jaiyn</p>
        </marquee>
    </div>
    </div>
    );
}


export default Marquee; 