import Navbar from "../components/NavBar";
import { Link, Outlet } from "react-router-dom";
import '../App.css';
// night mode
export default function DrownOut() {
  return (
    <>
    <Navbar />

    <h1 className="dejavu"> is it deja vu or just projection of your mind&hellip;
    <Link to='https://social.tunecore.com/linkShare?linkid=ZHDltNy8YpNbOFtPdQFAwQ'> <br/>crawl out now! Click here  !!</Link></h1>

    </>
  );
}

