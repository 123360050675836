import Navbar from "../components/NavBar";
import '../App.css';
// night mode
export default function TapIn() {
  return (
    <>
    <Navbar />

    <h1 className="dejavu"> paranoid bout parasocial&hellip;</h1>

    </>
  );
}