import Navbar from "../components/NavBar";
import '../App.css';
// night mode
export default function TuneIn() {
  return (
    <>
    <Navbar />

    <h1 className="dejavu"> i sing for sonic alchemy so I can be in tune with me&hellip;</h1>

    </>
  );
}