import React from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom"
import './App.css';
import Home from './pages/Home'
import DrownOut from './pages/drown_out'
import GiveIn from './pages/give_in'
import TapIn from './pages/tap_in'
import TuneIn from './pages/tune_in';
function AppRouter (){
return(

    <BrowserRouter>
    <Routes>

        <Route path="/"  element={
            <Home />
        } />

        <Route path="/drown_out"  element={
            <DrownOut />
        } />

        <Route path="/tune_in"  element={
            <TuneIn />
        } />

        <Route path="/tap_in"  element={
            <TapIn />
        } />

        <Route path="/give_in"  element={
            <GiveIn />
        } />



        

    
    </Routes>
    </BrowserRouter>
)

}

export default AppRouter