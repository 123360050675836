import Navbar from "../components/NavBar";
import '../App.css';
// night mode
export default function GiveIn() {
  return (
    <>
    <Navbar />

    <h1 className="dejavu"> does the Rain float or sink in the ocean&hellip; </h1>

    </>
  );
}